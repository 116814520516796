import React, { useState, useEffect } from "react";
import "./WalletData.css";
import { getBnbToUsdPrice, getSlothToUsdPrice } from "../fetch/tokenPrice";
import { checkWalletBalance } from "../fetch/walletBalance";
import get24hVolume from "../fetch/tokenVolume";
import getRewards from "../fetch/rewardsCalculator";
import { useAccount } from "wagmi";
import DisclaimerModal from "../DisclaimerModal/DisclaimerModal";
import ConnectionError from "../ConnectionError/ConnectionError";

const WalletData = () => {
  const [walletBalance, setWalletBalance] = useState("Loading");
  const [tokensValue, setTokensValue] = useState("Loading");
  const [bnbWalletBalance, setBnbWalletBalance] = useState("Loading");
  const [bnbValue, setBnbValue] = useState("Loading");
  const [tokenRewards, setTokenRewards] = useState("Loading");
  const [usdRewards, setUsdRewards] = useState("Loading");
  const [rewardsPerc, setRewardsPerc] = useState("Loading");
  const [walletName, setWalletName] = useState("Please Connect Your Wallet!");
  const { address, isConnected } = useAccount();
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isConnectionError, setIsConnectionError] = useState(false);

  const openDisclaimerModal = () => {
    setIsDisclaimerModalOpen(true);
  };

  const openConnectionError = () => {
    setIsConnectionError(true);
  };

  const totalSupply = "420000000000000000";
  const slothAddress = "0xb60617173471203ee7cf66ab869a78d50cde733b";
  const slothDecimals = 9;

  useEffect(() => {
    const fetchData = async () => {
      let walletToUse = address;
      if (!isConnected) {
        walletToUse = localStorage.getItem("connectedWallet"); // Retrieve the connected wallet address from local storage
        if (!isConnected) {
          setWalletName("Please Connect Your Wallet!");
          setWalletBalance("Loading");
          setTokensValue("Loading");
          setBnbWalletBalance("Loading");
          setBnbValue("Loading");
          setTokenRewards("Loading");
          setUsdRewards("Loading");
          setRewardsPerc("Loading");
          return;
        }
      }

      const shortenedAddress = address
        ? `${address.substring(0, 5)}...${address.substring(
            address.length - 5
          )}`
        : "";

      setWalletName(shortenedAddress);

      const balance = await checkWalletBalance(
        slothAddress,
        walletToUse,
        slothDecimals
      );

      if (balance.network === 1) {
        setWalletBalance(balance.tokenBalance.toLocaleString());
        setBnbWalletBalance(balance.bnbBalance);

        const bnbPrice = await getBnbToUsdPrice();
        const formatedBnbPrice = bnbPrice.toFixed(2);

        const slothUSD = await getSlothToUsdPrice(slothDecimals);

        setTokensValue((balance.tokenBalance * slothUSD).toFixed(2));
        setBnbValue((balance.bnbBalance * formatedBnbPrice).toFixed(2));

        const result = await get24hVolume();

        if (result.network === 1) {
          const zeroBalance = balance.zeroAddress;
          const circulationSupply = totalSupply - zeroBalance;

          const rewardsResults = await getRewards(
            balance.tokenBalance,
            result.totalAmount,
            circulationSupply,
            bnbPrice,
            slothUSD
          );

          const numberString = rewardsResults.holdingRewardsToken;
          const formattedNumberString = numberString.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
          setTokenRewards(formattedNumberString);
          setUsdRewards(rewardsResults.holdingRewardsUsd);
          setRewardsPerc(rewardsResults.rewardsHoldingsPercentage);

          console.log("Updated");
        } else {
          openConnectionError();
        }
      } else {
        openConnectionError();
      }
    };

    fetchData(); // Initial fetch when the component mounts

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // Refresh every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [isConnected, address]);

  return (
    <>
      <div className="wallet-address">
        <div className="item">{walletName}</div>
      </div>
      <div className="wallet">
        <div className="wallet-lable">
          <div className="item-title">BNB :</div>
          <div className="item">
            {bnbWalletBalance} ≈ ( ${bnbValue} )
          </div>
        </div>
        <div className="wallet-lable">
          <div className="item-title">SLOTH :</div>
          <div className="item">
            {walletBalance} ≈ ( ${tokensValue} )
          </div>
        </div>
        <div className="separator"></div>
        <div className="wallet-lable">
          <div className="item-title">24H Rewards :</div>
          <div className="item">
            {tokenRewards} ≈ ( ${usdRewards} )
          </div>
        </div>
        <div className="wallet-lable">
          <div className="item-title">24H Rewards % :</div>
          <div className="item">{rewardsPerc}%</div>
        </div>
      </div>
      <div className="note">
        <div className="item">
          * All numerical values have been estimated based on the current
          volume. Please refer to the
          <DisclaimerModal onClick={openDisclaimerModal}>
            Disclaimer
          </DisclaimerModal>
          for more information.
        </div>
      </div>
      {isConnectionError && <ConnectionError />}
    </>
  );
};

export default WalletData;
