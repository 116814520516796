import React from "react";
import logo from "../../assets/logo_large.png";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { Web3Button } from "@web3modal/react";
import "./Header.css";

const Header = () => {
  const chains = [bsc];
  const projectId = "32d62989fc27ab1c0b22c49d72f2da9b";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <div className="App-header">
          <div className="logo">
            <img src={logo} alt="Logo" />
            <h1>LazyTrack</h1>
          </div>
          <Web3Button />
        </div>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-background-color": "#2a353e9e",
  
        }}
      />
    </>
  );
};

export default Header;
