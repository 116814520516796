import React, { useState } from "react";
import Modal from "react-modal";
import "./DisclaimerModal.css";

const DisclaimerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="disclaimer-button" onClick={openModal}>
        Disclaimer
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Disclaimer</h2>
        <p>
          This information is not financial advice. The content provided on this
          website is for informational purposes only and should not be
          considered as financial or investment advice. The information
          presented is based on the current volume and market conditions, and it
          may not accurately reflect the actual figures or performance of any
          assets or investments.
        </p>
        <p>
          All numerical values displayed, including balances, values, rewards,
          and percentages, are estimates based on the available data and may
          vary. These estimates are subject to change and should not be relied
          upon for making financial decisions. We recommend consulting with a
          professional financial advisor before making any investment decisions.
        </p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

export default DisclaimerModal;
