import { useState, useEffect } from "react";
import "./TokenData.css";
import { getSlothTxn, getSlothToUsdPrice } from "../fetch/tokenPrice";
import get24hVolume from "../fetch/tokenVolume";
import ConnectionError from "../ConnectionError/ConnectionError";
import { checkConnection } from "../fetch/CheckConnections";

const TokenData = () => {
  const [totalVolume, setTotalVolume] = useState("Loading");
  const [totalTokenVolume, setTotalTokenVolume] = useState("Loading");
  const [buyVolume, setBuyVolume] = useState("Loading");
  const [sellVolume, setSellVolume] = useState("Loading");
  const [transactions, setTransactions] = useState("Loading");
  const [totalBuys, setTotalBuys] = useState("Loading");
  const [totalSells, setTotalSells] = useState("Loading");
  const [isConnectionError, setIsConnectionError] = useState(false);

  const openConnectionError = () => {
    setIsConnectionError(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const connection = await checkConnection();
      if (connection !== 0) {
        const price = await getSlothToUsdPrice();
        const volumeResults = await get24hVolume();
        const txns = await getSlothTxn();
        setTotalVolume(volumeResults.totalAmount.toFixed(2));
        setBuyVolume(volumeResults.totalBuys.toFixed(2));
        setSellVolume(volumeResults.totalSells.toFixed(2));
        setTransactions(volumeResults.numberOfTransactions);
        setTotalBuys(txns.totalBuysTransaction);
        setTotalSells(txns.totalSellsTransaction);

        const usdToToken = (volumeResults.totalAmount / price).toFixed(0);

        const numberString = usdToToken;
        const formattedNumberString = numberString.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        setTotalTokenVolume(formattedNumberString);
      } else {
        openConnectionError();
      }
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // refresh every 10 second

    // cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [isConnectionError]);

  return (
    <>
      <div className="token-address">
        <div className="item">SLOTH</div>
      </div>
      <div className="token-status">
        <div className="transaction-lable">
          <div className="item">Sells</div>
          <div className="item">TXNs</div>
          <div className="item">Buys</div>
        </div>
        <div className="transaction-counts">
          <div className="txn-sell">{totalSells}</div>
          <div className="txn">{transactions}</div>
          <div className="txn-buy">{totalBuys}</div>
        </div>
        <div className="separator"></div>
        <div className="token-lable">
          <div className="item-title">24H USD Volume :</div>
          <div className="item">${totalVolume}</div>
        </div>
        <div className="token-lable">
          <div className="item-title">24H SLOTH Volume :</div>
          <div className="item">{totalTokenVolume}</div>
        </div>
        <div className="separator"></div>
        <div className="token-lable">
          <div className="item-title">Buy volume :</div>
          <div className="item">${buyVolume}</div>
          <div className="item-title">Sell volume :</div>
          <div className="item">${sellVolume}</div>
        </div>
      </div>
      <div className="token-note">
        <div className="item">
          * All numerical values have been estimated based on the current volume
        </div>
      </div>
      {isConnectionError && <ConnectionError />}
    </>
  );
};

export default TokenData;
