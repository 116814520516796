import React, { useState, useEffect } from "react";
import "./Leaderboard.css";
import get24hVolume from "../fetch/tokenVolume";
import bscscan from "../../assets/bscscan-logo-circle.png";

const Leaderboard = () => {
  const temp = [
    {
      address: "Loading",
      amount: 0.0,
    },
    {
      address: "Loading",
      amount: 0.0,
    },
    {
      address: "Loading",
      amount: 0.0,
    },
  ];

  const [leaderboardBuys, setLeaderboardBuys] = useState(temp);
  const [leaderboardSells, setLeaderboardSells] = useState(temp);

  useEffect(() => {
    const fetchData = async () => {
      const leaderboardData = await get24hVolume();

      setLeaderboardBuys(leaderboardData.sortedLeaderboardBuys.slice(0, 3));
      console.log(leaderboardBuys);
      setLeaderboardSells(leaderboardData.sortedLeaderboardSells.slice(0, 3));
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // Refresh every 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="token-address">
        <div className="item">Leaderboard</div>
      </div>
      <div className="leaderboard-pos">
        <div className="leaderboard-title-container">
          <div className="leaderboard-title">Top 24H Sellers</div>
        </div>
        <div className="buys-pos">
          {leaderboardBuys.map((entry, index) => (
            <div
              key={index}
              className={`buy-item ${
                index % 2 === 0 ? "white-bg" : "black-bg"
              }`}
            >
              <p>
                <span className="count-number">{index + 1}. </span>
                {`${entry.address.substring(0, 5)}...${entry.address.slice(
                  -5
                )}`}
              </p>
              <p>
                ${entry.amount.toFixed(2)}
                <a
                  className="link-to-address"
                  href={`https://bscscan.com/address/${entry.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="bsclogo" src={bscscan} alt="bscscan"></img>
                </a>
              </p>
            </div>
          ))}
        </div>
        <div className="leaderboard-title-container">
          <div className="leaderboard-title">Top 24H Sellers</div>
        </div>
        <div className="sells-pos">
          {leaderboardSells.map((entry, index) => (
            <div
              key={index}
              className={`sell-item ${
                index % 2 === 0 ? "sell-white-bg" : "sell-black-bg"
              }`}
            >
              <p>
                <span className="count-number">{index + 1}. </span>
                {`${entry.address.substring(0, 5)}...${entry.address.slice(
                  -5
                )}`}
              </p>
              <p>
                ${entry.amount.toFixed(2)}
                <a
                  className="link-to-address"
                  href={`https://bscscan.com/address/${entry.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="bsclogo" src={bscscan} alt="bscscan"></img>
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="leaderboard-note">
        <div className="note-item">
          * These leaderboards are intended for recreational purposes only and
          do not confer any benefits or awards.
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
