import { useState, useEffect } from "react";
import "./mainComponent.css";
import DexScreenerEmbed from "../fetch/dexscreener";
import { getBnbToUsdPrice, getSlothToUsdPrice } from "../fetch/tokenPrice";
import WalletData from "../WalletData/WalletData";
import TokenData from "../TokenData/TokenData";
import Leaderboard from "../Leaderboard/Leaderboard";
import bnbLogo from "../../assets/bnb-logo.png";
import slothLogo from "../../assets/logo_large.png";
import ConnectionError from "../ConnectionError/ConnectionError";

const MainComponent = () => {
  const [bnbPrice, setBnbPrice] = useState("Loading");
  const [slothUSD, setSlothUSD] = useState("Loading");
  const [isConnectionError, setIsConnectionError] = useState(false);

  const openConnectionError = () => {
    setIsConnectionError(true);
  };

  const slothDecimals = 9;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bnbPrice = await getBnbToUsdPrice();
        console.log(bnbPrice);
        if (bnbPrice !== 0) {
          const formatedBnbPrice = bnbPrice.toFixed(2);

          const slothUSD = await getSlothToUsdPrice(slothDecimals);
          if (slothUSD !== 0) {
            const formattedSlothUSD = formatNumber(slothUSD);

            setBnbPrice("$" + formatedBnbPrice);
            setSlothUSD(formattedSlothUSD);
          } else {
            openConnectionError();
          }
        } else {
          openConnectionError();
        }
      } catch (error) {
        openConnectionError();
      }
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // refresh every 10 seconds

    // cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [isConnectionError]);

  const formatNumber = (number) => {
    const formattedNumber = number;
    const [integerPart, decimalPart] = formattedNumber.split(".");
    const subscript =
      String.fromCharCode(8320 + 1) + String.fromCharCode(8320 + 2); // Subscripts for 1, 2

    let trimmedDecimalPart = decimalPart.replace(/^0+/, ""); // Remove leading zeros

    if (trimmedDecimalPart === "") {
      trimmedDecimalPart = "0"; // Ensure at least one digit after the decimal point
    }

    return `$0.0${subscript}${trimmedDecimalPart}`;
  };

  return (
    <div className="main-wrapper">
      <div className="info">
        <div className="prices">
          <div className="small-logo">
            <img src={slothLogo} alt="sloth-logo"></img>
          </div>
          <div className="token-title">SLOTH : {slothUSD}</div>
          <div className="token-title">|</div>
          <div className="small-logo">
            <img src={bnbLogo} alt="bnb-logo"></img>
          </div>
          <div className="token-title">BNB : {bnbPrice}</div>
        </div>
        <WalletData />
        <TokenData />
        <Leaderboard />
      </div>
      <div className="chart">
        <DexScreenerEmbed />
      </div>
      {isConnectionError && <ConnectionError />}
    </div>
  );
};

export default MainComponent;
